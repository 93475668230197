import {
  ADMIN_SYS,
  ADMINISTRADOR,
  CLIENTE,
  COMPRAS,
  CONSULTA_FINANCEIRO,
  CONTABIL,
  CONTROLADORIA,
  CREDITO_COBRANCA,
  EMISSOR_NFE,
  FATURAMENTO,
  FATURAMENTO_NFE,
  FINANCEIRO,
  GERENTE_OSORIO,
  GESTAO,
  OPERACIONAL,
  LABORATORIO,
  LOGISTICA,
  NAO_APROVADO,
  PUBLICIDADE,
  REPRESENTANTE,
  SUPERVISOR,
  SUPERVISOR_NACIONAL,
  VENDEDOR_COMERCIO,
  TINTOMETRICO,
  CAIXA,
  VENDEDOR,
  IMPORTACOES,
  FINANCEIRO_GERENTE,
  ALMOXARIFADO,
  EXPEDICAO,
  GUARITA,
} from "@/constants/profiles.js";

export default [
  {
    component: () => import("@/components/auth/password/ForgotPasswordEmailForm.vue"),
    meta: { requiresGuest: true },
    name: "esqueci-minha-senha",
    path: "/esqueci-minha-senha",
  },
  {
    component: () => import("@/components/auth/password/PasswordResetForm.vue"),
    meta: { requiresGuest: true },
    name: "redefinir-senha",
    path: "/redefinir-senha/:token",
  },
  {
    component: () => import("@/components/auth/LoginForm.vue"),
    meta: { requiresGuest: true },
    name: "login",
    path: "/",
  },
  {
    component: () => import("@/components/auth/RegistrationForm.vue"),
    meta: { requiresGuest: true },
    name: "registro",
    path: "/registro",
  },
  {
    component: () => import("@/components/auth/emitente/emitente.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CLIENTE,
        COMPRAS,
        CONSULTA_FINANCEIRO,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        FATURAMENTO,
        FATURAMENTO_NFE,
        FINANCEIRO,
        GERENTE_OSORIO,
        LABORATORIO,
        LOGISTICA,
        PUBLICIDADE,
        REPRESENTANTE,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        VENDEDOR_COMERCIO,
        TINTOMETRICO,
        CAIXA,
        VENDEDOR,
        IMPORTACOES,
        FINANCEIRO_GERENTE,
        ALMOXARIFADO,
        EXPEDICAO,
        GUARITA,
      ],
      requiresAuth: true,
    },
    name: "emitentes",
    path: "/emitentes",
  },
  {
    component: () => import("@/components/auth/RegistrationConfirmation.vue"),
    meta: { requiresAuth: true },
    name: "confirmacao",
    path: "/confirmacao",
  },
];
