import {
  ADMIN_SYS,
} from "@/constants/profiles.js";

export default [
  {
    component: () => import("@/components/almoxarifado/MenuAlmoxarifado.vue"),
    name: "almoxarifado.menu",
    path: "/almoxarifado",
    meta: {
      authorize: [
        ADMIN_SYS,
      ],
    },
  },
  {
    component: () => import("@/components/almoxarifado/CadastroProdutoAlmoxarifado.vue"),
    name: "almoxarifado.cadastro.produto",
    path: "/almoxarifado/cadastro/produto",
    meta: {
      authorize: [
        ADMIN_SYS,
      ],
    },
  },
  {
    component: () => import("@/components/almoxarifado/CadastroProdutoAlmoxarifado.vue"),
    name: "almoxarifado.edit.produto",
    path: "/almoxarifado/edit/produto/:id",
    meta: {
      authorize: [
        ADMIN_SYS,
      ],
    },
  },
  {
    component: () => import("@/components/almoxarifado/CadastroMovimentacaoAlmoxarifado.vue"),
    name: "almoxarifado.edit.movimentacao",
    path: "/almoxarifado/edit/movimentacao/:id",
    meta: {
      authorize: [
        ADMIN_SYS,
      ],
    },
  },
  {
    component: () => import("@/components/almoxarifado/GerenciarProdutosAlmoxarifado.vue"),
    name: "almoxarifado.gerenciar.produtos",
    path: "/almoxarifado/produtos/gerenciar",
    meta: {
      authorize: [
        ADMIN_SYS,
      ],
    },
  },
  {
    component: () => import("@/components/almoxarifado/CadastroMovimentacaoAlmoxarifado.vue"),
    name: "almoxarifado.cadastrar.movimentacoes",
    path: "/almoxarifado/movimentacoes/cadastrar",
    meta: {
      authorize: [
        ADMIN_SYS,
      ],
    },
  },
  {
    component: () => import("@/components/almoxarifado/GerenciarMovimentacoesAlmoxarifado.vue"),
    name: "almoxarifado.gerenciar.movimentacoes",
    path: "/almoxarifado/movimentacoes/gerenciar",
    meta: {
      authorize: [
        ADMIN_SYS,
      ],
    },
  },
  {
    component: () => import("@/components/almoxarifado/VisualizarMovimentacaoAlmoxarifado.vue"),
    name: "almoxarifado.visualizar.movimentacoes",
    path: "/almoxarifado/movimentacoes/visualizar/:id",
    meta: {
      authorize: [
        ADMIN_SYS,
      ],
    },
  },
  {
    component: () => import("@/components/almoxarifado/HistoricoProdutosAlmoxarifado.vue"),
    name: "almoxarifado.visualizar.historico",
    path: "/almoxarifado/movimentacoes/historico/:id",
    meta: {
      authorize: [
        ADMIN_SYS,
      ],
    },
  },
];
