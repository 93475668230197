export const NAO_APROVADO = 1,
  ADMINISTRADOR = 2,
  REPRESENTANTE = 3,
  SUPERVISOR = 4,
  CLIENTE = 5,
  FATURAMENTO_NFE = 6,
  FATURAMENTO = 7,
  CONTABIL = 8,
  CREDITO_COBRANCA = 9,
  LOGISTICA = 10,
  COMPRAS = 11,
  LABORATORIO = 12,
  FINANCEIRO = 13,
  GERENTE_OSORIO = 14,
  SUPERVISOR_NACIONAL = 15,
  CONTROLADORIA = 16,
  ADMIN_SYS = 17,
  CONSULTA_FINANCEIRO = 18,
  VENDEDOR_COMERCIO = 19,
  EMISSOR_NFE = 20,
  GESTAO = 22,
  OPERACIONAL = 24,
  REVOGADO = 99,
  PUBLICIDADE = 100,
  GERENTE_COMERCIAL = 101,
  GERENTE_DE_VENDAS = 102,
  TINTOMETRICO = 103,
  CAIXA = 106,
  VENDEDOR = 107,
  IMPORTACOES = 108,
  FINANCEIRO_GERENTE = 109,
  ALMOXARIFADO = 110,
  EXPEDICAO = 111,
  GUARITA = 112,
  // TODO: Remove this entire object and return it from an api call.
  PAPEIS = [
    { id: 3, nome: "representante", descricao: "Representante" },
    { id: 4, nome: "supervisor", descricao: "Supervisor" },
    { id: 5, nome: "cliente", descricao: "Cliente" },
    { id: 6, nome: "faturamento_nfe", descricao: "Faturamento NFe" },
    { id: 19, nome: "vendedor_comercio", descricao: "Vendedor" },
    { id: 20, nome: "emissor_nfe", descricao: "Emissao NFe" },
    { id: 22, nome: "gestao", descricao: "Gestao" },
    { id: 24, nome: "operacional", descricao: "Operacional" },
    { id: 99, nome: "revogado", descricao: "Revogado" }, // TODO: Remove from this list and transform in a button.
    { id: 100, nome: "publicidade", descricao: "Publicidade" },
    { id: 101, nome: "gerente_comercial", descricao: "Gerente Comercial" },
    { id: 102, nome: "gerente_de_vendas", descricao: "Gerente de Vendas" },
    { id: 103, nome: "tintometrico", descricao: "tintometrico" },
    { id: 106, nome: "caixa", descricao: "caixa" },
    { id: 107, nome: "vendedor", descricao: "vendedor" },
    { id: 108, nome: "importacoes", descricao: "importacoes" },
    { id: 109, nome: "financeiro_gerente", descricao: "Financeiro Gerente" },
    { id: 110, nome: "almoxarifado", descricao: "Almoxarifado" },
    { id: 111, nome: "expedicao", descricao: "Expedicao" },
    { id: 112, nome: "guarita", descricao: "Guarita" },
  ];

export default {
  NAO_APROVADO: 1,
  ADMINISTRADOR: 2,
  REPRESENTANTE: 3,
  SUPERVISOR: 4,
  CLIENTE: 5,
  FATURAMENTO_NFE: 6,
  FATURAMENTO: 7,
  CONTABIL: 8,
  CREDITO_COBRANCA: 9,
  LOGISTICA: 10,
  COMPRAS: 11,
  LABORATORIO: 12,
  FINANCEIRO: 13,
  GERENTE_OSORIO: 14,
  SUPERVISOR_NACIONAL: 15,
  CONTROLADORIA: 16,
  ADMIN_SYS: 17,
  CONSULTA_FINANCEIRO: 18,
  VENDEDOR_COMERCIO: 19,
  EMISSOR_NFE: 20,
  GESTAO: 22,
  OPERACIONAL: 24,
  REVOGADO: 99,
  PUBLICIDADE: 100,
  GERENTE_COMERCIAL: 101,
  GERENTE_DE_VENDAS: 102,
  TINTOMETRICO: 103,
  CAIXA: 106,
  VENDEDOR: 107,
  IMPORTACOES: 108,
  FINANCEIRO_GERENTE: 109,
  ALMOXARIFADO: 110,
  EXPEDICAO: 111,
  GUARITA: 112,
};
