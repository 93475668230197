import {
  ADMIN_SYS,
  ADMINISTRADOR,
  CLIENTE,
  COMPRAS,
  CONSULTA_FINANCEIRO,
  CONTABIL,
  CONTROLADORIA,
  CREDITO_COBRANCA,
  EMISSOR_NFE,
  FATURAMENTO,
  FATURAMENTO_NFE,
  FINANCEIRO,
  GERENTE_OSORIO,
  LABORATORIO,
  LOGISTICA,
  PUBLICIDADE,
  REPRESENTANTE,
  SUPERVISOR,
  SUPERVISOR_NACIONAL,
  TINTOMETRICO,
  CAIXA,
  GESTAO,
  OPERACIONAL,
  VENDEDOR,
  VENDEDOR_COMERCIO,
  FINANCEIRO_GERENTE,
  IMPORTACOES,
  ALMOXARIFADO,
  EXPEDICAO,
  GUARITA,
} from "@/constants/profiles.js";

export default [
  {
    path: "/home",
    name: "home",
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CLIENTE,
        COMPRAS,
        CONSULTA_FINANCEIRO,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        EMISSOR_NFE,
        FATURAMENTO,
        FATURAMENTO_NFE,
        FINANCEIRO,
        GERENTE_OSORIO,
        LABORATORIO,
        LOGISTICA,
        PUBLICIDADE,
        REPRESENTANTE,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        TINTOMETRICO,
        CAIXA,
        VENDEDOR,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
        SUPERVISOR,
        IMPORTACOES,
        ALMOXARIFADO,
        EXPEDICAO,
        GUARITA,
      ],
    },
    component: () => import("@/components/home/Home.vue"),
  },
  {
    path: "/home-dash",
    name: "home-dash",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CLIENTE,
        REPRESENTANTE,
        VENDEDOR_COMERCIO,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        GESTAO,
        OPERACIONAL,
        GERENTE_OSORIO,
        FINANCEIRO_GERENTE,
        ALMOXARIFADO,
        EXPEDICAO,
        GUARITA,
      ],
    },
    component: () => import("@/components/home/MenuDashboard.vue"),
  },
  {
    path: "/home-faturamento",
    name: "home-faturamento",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CLIENTE,
        REPRESENTANTE,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        GERENTE_OSORIO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/home/MenuFaturamento.vue"),
  },
  {
    path: "/home-financeiro",
    name: "home-financeiro",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CLIENTE,
        REPRESENTANTE,
        VENDEDOR_COMERCIO,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        GESTAO,
        FINANCEIRO,
        GERENTE_OSORIO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/home/MenuFinanceiro.vue"),
  },
];
